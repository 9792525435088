import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import Keycloak from 'keycloak-js';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { MykeycloakService } from './mykeycloak.service';
import { UserKeycloakDtoNBK } from '../api/nbk';

export interface KeyCloackUserCheckCompleted {
  completed: Array<boolean>;
}
export interface KeyCloackUserExtended extends Keycloak.KeycloakProfile {
  attributes?: KeyCloackUserCheckCompleted;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  private userIsCompleted: boolean = false;
  private keyCloackUserExtended: KeyCloackUserExtended;

  constructor(
    private myKeycloakService: MykeycloakService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.handleAuthentication(route.data);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.handleAuthentication(childRoute.data);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.handleAuthentication(route.data);
  }

  private async handleAuthentication(data?: Data): Promise<boolean | UrlTree> {
    this.checkUserIsCompleted();
    if (this.userIsCompleted) {
      if (data && data.permission) {
        let hasPermission = false;
        data.permission.map((role: UserKeycloakDtoNBK.RolesEnum) => {
          if (this.authService.permissions[role]) {
            hasPermission = true;
          }
        });
        return hasPermission;
      } else {
        return true;
      }
    } else {
      return this.router.createUrlTree(['user-profile']);
    }
  }

  private checkUserIsCompleted() {
    this.keyCloackUserExtended = this.myKeycloakService.user;

    if (
      this.keyCloackUserExtended.attributes?.completed &&
      this.keyCloackUserExtended.attributes?.completed.length > 0
    ) {
      this.userIsCompleted =
        this.keyCloackUserExtended.attributes?.completed[0];
    }
  }
}
